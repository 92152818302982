export default function tableBasicSearch() {
	const attr = "js-table-search";
	const els = document.querySelectorAll(`[${attr}]`);
	const url = new URL(window.location);
	let filtersOBJ = GLOBAL.splitFilters(url.search) || {};
	console.log(attr, filtersOBJ);
	
	if (!els.length) return;
	
	els.forEach((i) => {
		const key = i.getAttribute('data-key') || 'q'
		const wrapper = i.closest(".input-container");
		// const btn = wrapper.querySelector("button");
		// const btnClear = wrapper.querySelector("svg");
		const btn_search = wrapper.querySelector("#button-search");
		
		if(filtersOBJ[key]) {
			i.value = filtersOBJ[key]
			// mostra clear
		}

		// btnClear.addEventListener("click", () => {
		// 	//não limpa a ordem de listagem com botao (x) em input de busca
		// 	if (filtersOBJ?.order) {
		// 		filtersOBJ = {
		// 			order: filtersOBJ.order,
		// 		};
		// 	} else {
		// 		filtersOBJ = {};
		// 	}

		// 	let newFilters = GLOBAL.generateStringFilterFromObject(filtersOBJ);
		// 	newFilters = newFilters.replace('&x=', '')
		// 	window.location.href = `${url.origin}${url.pathname}${newFilters}`;
		// });
		// if (btn) btn.addEventListener("click", search(i));
		btn_search?.addEventListener("click", (e) => {
			search(i)(e)
		});
		// if (btn_search) btn_search.addEventListener("click", search(i));
		i.addEventListener("keyup", (e) => {
			console.log(e);
			if (e.keyCode == 13) {
				search(i)(e);
			}
			// showBtnClearFilter(i, btnClear);
		});

		// if (!filtersOBJ) return
		// if (filtersOBJ["filter"]) {
		// 	i.value = filtersOBJ["filter"]
		// } else {
		// 	return
		// }
	});

	function search(input) {
		const key = input.getAttribute('data-key') || 'q'
		return (e) => {
			// const target = e.currentTarget;
			// const btnClear = input.nextElementSibling;
			if (!input.value || filtersOBJ[key] === input.value) return

			// showBtnClearFilter(input, btnClear);

			if (filtersOBJ) {
				filtersOBJ[key] = input.value;
			} else {
				filtersOBJ = {};
				filtersOBJ[key] = input.value;
			}
			const { page, ...rest } = filtersOBJ;
			filtersOBJ = rest;
			let newFilters = GLOBAL.generateStringFilterFromObject(filtersOBJ);
			newFilters = newFilters.replace('&x=', '')
			if (window.location.search !== newFilters) {
				window.location.href = `${url.origin}${url.pathname}${newFilters}`;
				console.log(window.location.href)
			}
		};
	}

	// function showBtnClearFilter(input, btn) {
	// 	if (input.value != "") {
	// 		btn.classList.remove("hidden");
	// 	} else {
	// 		btn.classList.add("hidden");
	// 	}
	// }
}
