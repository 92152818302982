export default function inlineFunctions() {
	const hasInlines = document.querySelector("[data-js-inline]")
	if (!hasInlines) return

	function addRemoveEventInExistingInlines() {
		const btnsRemoveInline = document.querySelectorAll("[class*='remove_inline']")
		btnsRemoveInline.forEach(btn => {
			const inline = btn.closest('.inline-item')
			btn.addEventListener('click', () => removeInline(inline))
		})
	}

	function checkMaxNumAllForms() {
		const maxNumbersElements = document.querySelectorAll("[id$='-MAX_NUM_FORMS']")
		// console.log(maxNumbersElements)
		maxNumbersElements.forEach(numberElement => {
			const wrapperInline = numberElement.closest('.wrapper-inline')
			// console.log(numberElement.value, numberElement);
			checkMaxNumForm(numberElement.value, wrapperInline)
		})
	}

	function checkMaxNumForm(totalForms, wrapperInline) {
		// console.log(wrapperInline)
		const inlineItens = wrapperInline.querySelectorAll('.inline-item')
		if (inlineItens.length === Number(totalForms)) {
			const dataJsInline = wrapperInline.dataset.jsInline
			const btnAddInline = document.querySelector(`.add_inline[data-js-inline='${dataJsInline}']`)
			btnAddInline.classList.add('hidden')
		}
	}

	function addAddEventInExistingInlines() {
		const btnsAddInline = document.querySelectorAll('.add_inline')
		btnsAddInline && btnsAddInline.forEach(btnAddInline => {
			btnAddInline.addEventListener('click', () => addInline(btnAddInline.dataset.jsInline))
		})
	}

	function addInline(dataJsInline) {
		
		const wrapperInline = document.querySelector(`.wrapper-inline[data-js-inline='${dataJsInline}']`)
		const container = wrapperInline.querySelector('[js-container-parcela]')
		console.log(container, 'aqqq')
		const inputMaxForms = wrapperInline.querySelector("[id$='-MAX_NUM_FORMS']")
		const inputFormIndex = wrapperInline.querySelector("[id$='-TOTAL_FORMS']")
		//  console.log(inputFormIndex);
		const intFormIndex = parseInt(inputFormIndex.value)
		const emptyForm = $(`.empty_inline_form[data-js-inline='${dataJsInline}']`)


		const amountForms = document.querySelector(`.qtd-inlines[data-js-inline='${dataJsInline}']`)
		
		if(container){
			let isVazio = false
				console.log('entrou')
				const inputs = container.querySelectorAll('input')
				console.log(inputs)
				inputs.forEach(input => {
					if(input.value == ''){
						isVazio = true
					}
				})
				console.log('pass', isVazio)
				if(isVazio){
					GLOBAL.showToastify('Preencha todos os campos referente a parcela')
					return
				}else{
					if (amountForms) {
						addMultiInline()
					}else{
						addSingleInline()
					}
				}
			}else{
				if (amountForms) {
			
					addMultiInline()
				}else{
					addSingleInline()
				}
				
			}
		

		const callbacks = wrapperInline.querySelectorAll("[data-callback]")
		callbacks.forEach(callback => $(callback).click())

		function addSingleInline() {
			// GLOBAL.showToastify("Single!")
			$(wrapperInline).append(emptyForm.html().replace(/__prefix__/g, intFormIndex))
			const removeBtn = wrapperInline.querySelector(`.remove_inline-${intFormIndex}`)
			const inline = document.querySelector(`.inline-${intFormIndex}[data-js-inline=${dataJsInline}]`)
			removeBtn.addEventListener('click', () => removeInline(inline))


			inputFormIndex.value = intFormIndex + 1
			if (wrapperInline.children) {
				[...wrapperInline.children].forEach(child => {
					child.setAttribute("new-inline", false)

				})
			}
			extraConfigs(inline)
			checkMaxNumForm(inputMaxForms.value, wrapperInline)
		}

		function addMultiInline() {
			//GLOBAL.showToastify("Multi!")
			const removeBtns = wrapperInline.querySelectorAll(`[data-js-inline='inline_parcela'][class*='remove_inline']`)
			removeBtns.forEach(btn => $(btn).click())

			for (let i = 0; i < amountForms.value; i++) {
				const inputFormIndex = wrapperInline.querySelector("[id$='-TOTAL_FORMS']")
				const intFormIndex = parseInt(inputFormIndex.value)

				$(wrapperInline).append(emptyForm.html().replace(/__prefix__/g, intFormIndex))
				const removeBtn = wrapperInline.querySelector(`.remove_inline-${intFormIndex}`)
				const inline = document.querySelector(`.inline-${intFormIndex}[data-js-inline=${dataJsInline}]`)
				if (removeBtn) {
					removeBtn.addEventListener('click', () => removeInline(inline))
				}


				inputFormIndex.value = intFormIndex + 1
				if (wrapperInline.children) {
					[...wrapperInline.children].forEach(child => {
						child.setAttribute("new-inline", false)

					})
				}
				extraConfigs(inline)
				checkMaxNumForm(inputMaxForms.value, wrapperInline)
			}
		}
	}


	function removeRequired(listItens) {
		listItens.forEach(i => {
			if (i.getAttribute('required') !== null) {	
				i.removeAttribute('required')
			}
		})
	}

	function removeInline(inline) {
		
		const wrapperInline = inline.closest(".wrapper-inline")
		const selects = inline.querySelectorAll('select')
		const inputs = inline.querySelectorAll('input')
		
		removeRequired(selects)
		removeRequired(inputs)

		const inputMaxForms = wrapperInline.querySelector("[id$='-MAX_NUM_FORMS']")
		const deleteBtn = inline.querySelector(`[id$='-DELETE'`)
		deleteBtn.setAttribute('checked', true)
		inline.classList.add('hidden')

		checkMaxNumForm(inputMaxForms.value, wrapperInline)
	}

	function hideInitialCheckedInline() {
		const deleteBtns = document.querySelectorAll(`[id$='-DELETE'`)

		deleteBtns.forEach(deleteBtn => {
			if (deleteBtn.checked) {
				const inline = deleteBtn.closest('[data-js-inline]')
				if (inline) {
					const selects = inline.querySelectorAll('select')
					const inputs = inline.querySelectorAll('input')
					removeRequired(selects)
					removeRequired(inputs)
					inline.classList.add('hidden')
				}

			}
		})
	}

	function extraConfigs(inline) {
		inline.setAttribute("new-inline", true)
		const selects = inline.querySelectorAll('select')
		const props = {
			searchEnabled: true,
			searchChoices: true,
		}

		const items = [...selects].map(select => GLOBAL.initChoices(select, props))
		//add disabled na primeira opçao do select, caso seja vazio
		addOptionDisabledInChoices(items)

		window.listOfChoices = [...listOfChoices, ...items]


		const wrapperAnexos = inline.querySelector('.wrapper-anexos')

		// remove o desabilitado caso o produto tenha anexos
		selects.forEach(select => {
			if (select.getAttribute('js-select-produto')) {
				select.addEventListener('change', () => {
					wrapperAnexos && wrapperAnexos.classList.remove('disabled-container')
				})
			}
		})
	}


	function addOptionDisabledInChoices(lista) {

		const options = lista
			.map(choice => choice._presetChoices)
			.map(options => options.map(opt => {
				if (opt.value == '') {
					opt.disabled = true
				}
				return opt
			}))

		lista.map((choice, index) => {
			return choice && choice.setChoices(options[index], 'value', 'label', true)
		})

	}

	(function init() {
		addAddEventInExistingInlines()
		addRemoveEventInExistingInlines()
		checkMaxNumAllForms()
		hideInitialCheckedInline()
	})()
}