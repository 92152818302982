export default function breadcrumb() {
	const attr = "[js-breadcrumb]";
	const breadcrumb = document.querySelector(`.breadcrumb${attr}`);
	const objStr = breadcrumb?.dataset.obj;

	if (!breadcrumb || !objStr) return;

	const obj = JSON.parse(objStr.replaceAll(`'`, `"`));
	const aLength = obj.length - 1;
	const strCorbansUserUpdate = ['filiais-list', 'user-update']
	let items;
	if (window.location.pathname.includes(strCorbansUserUpdate[0]) && window.location.pathname.includes(strCorbansUserUpdate[1])) {
		obj[0].name = full_title ? full_title.innerText : ''
	}
	//console.log("OBJJ", obj);
	if (GLOBAL.isTablet) {
		items = obj
			.filter((param) => param.mobile)
			.map(
				({ name, slug, link }, index) => `
			<li class="${index === aLength ? "active" : ""}">
				<a ${link ? `href="${link}"` : ""} data-slug="${slug}">${name}</a>
			</li>
			`
			);
	} else {
		items = obj.map(
			({ name, slug, link }, index) => `
			<li class="${index === aLength ? "active" : ""}">
				<a ${link ? `href="${link}"` : ""} data-slug="${slug}">${name}</a>
			</li>
			`
		);
	}

	const template = () => {
		return `
			<ul class="flex flex-wrap breadcrumb-wrapper">
				${items.join("")}
			</ul>
		`;
	};

	breadcrumb.innerHTML = template();

	if (GLOBAL.isTablet) {
		const breadMobile = document.querySelector(".breadcrumb-mobile");
		breadMobile.appendChild(breadcrumb);
	}
}
