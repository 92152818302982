export default function dataTablesInit() {
	const tables = document.querySelectorAll("table[js-dataTables]")
	if (!tables.length) return
	tables.forEach(t => {
		const props = t.dataset.props || {}

		const Dtable = $(t).DataTable({
			
			"scrollX": true,
			"language": {
				"show": "Visualizar",
				"lengthMenu": "Linhas por página_MENU_ ",
				"zeroRecords": "Nada encontrado",
				"info": "Exibindo página _PAGE_ de _PAGES_",
				"infoEmpty": "Nada encontrado",
				"infoFiltered": "(filtrado de _MAX_ no total)",
				"search": "",
				
				"paginate": {
					"previous": "<svg ><use xlink:href='#icon_anterior'></use></svg>",
					"next": "<svg ><use xlink:href='#icon_proximo'></use></svg>"
				}
			},
			
			"autoWidth": true,
			"buttons":true,
			"ordering": true,
			"order": [[0, "desc"]],
			// rowReorder: {
			//     selector: 'td:nth-child(2)'
			// },
			select: {
				items: 'cell'
			},
			buttons:['excel'],
			responsive: true,
			paginate: true,
			search: false,
			info: false,
			...props

		})

		GLOBAL.wrap_single(t, "div", ["table--container"])

		// t.querySelectorAll("thead th")
		// 	?.forEach(t => t.addEventListener("click", () => {
		// 		Dtable.columns.adjust().draw();
		// 	}))
		// console.log(Dtable.buttons())
		// Dtable.buttons().container().appendTo('#example_wrapper');
	})
	

	const input = document.querySelector('.dataTables_filter input')
	const select = document.querySelector('.dataTables_length select')
	const dataFilter = input.closest('div')
	const dataSelect = select.closest('.dataTables_length')

	input.classList.add('input-text', 'h-48')
	input.setAttribute('js-ativo','')
	input.setAttribute('placeholder','Buscar')
	
	dataSelect.classList.add("flex","md2:self-end")
	dataFilter.classList.remove('dataTables_filter')
	dataFilter.classList.add('self-start')
	select.setAttribute('js-choices', '')
	GLOBAL.wrap_single(select, "div", ["select-container", "h-48","select-left"])
	
	GLOBAL.wrap_single(dataFilter, "div", ["header-table","md2:flex-col"])
	document.querySelector('.header-table').appendChild(dataSelect)
	

}