import accordion from "./modules/accordion";
import breadcrumb from "./modules/breadcrumb";
import dataMasks from "./modules/dataMasks";
import dataTablesInit from "./modules/dataTablesInit";
import dropdown from "./modules/dropdown";
import inlineFunctions from "./modules/inlineFunctions";
import inputToogleActive from "./modules/inputToogleActive";
import multiLevelMenu from "./modules/multiLevelMenu";
import showCorrectSection from "./modules/showCorrectSection";
import showToast from "./modules/showToast";
import startAllChoices from "./modules/startAllChoices";
import tabs from "./modules/tabs";
import mostraSenha from "./modules/mostraSenha";
import tableSelecionarColunas from "./modules/tableSelecionarColunas.js";
import tableBasicOrdenation from "./modules/tableBasicOrdenation.js";
import addFilterURLAndReload from "./modules/addFilterURLAndReload.js";
import tableBasicSearch from "./modules/tableBasicSearch.js";
import tableBasicQnt from "./modules/tableBasicQnt.js";
import tableBasicRecentes from "./modules/tableBasicRecentes.js";
import badge from "./modules/badge.js";
import modalFiltrar from "./modules/modalFiltrar.js";
import loader from "./modules/loader.js";
import IMaskInit from "./modules/IMask";



dataTablesInit();
dataMasks(); // Aplica máscaras nos inputs
accordion();
breadcrumb();
dropdown();
inlineFunctions(); // Faz as implementações relacionadas aos inlines
inputToogleActive(); // muda class ativa nos inputs
// multiLevelMenu();
showCorrectSection(); //mostra elemento de acordo com parametro na url
showToast();
startAllChoices(); //inicia bibiloteca Choices.js
tabs();
badge();
mostraSenha();
addFilterURLAndReload(); //adiona um filtro na url e recarrega
// teste

//table
tableSelecionarColunas(); //show hide coluns
tableBasicOrdenation(); // ordenacao ao clicar na thead
tableBasicSearch(); //Busca da tabela basica
tableBasicQnt(); //quantidade de items vcisiveis na tabela
tableBasicRecentes(); //ordena por id maior ou menor
IMaskInit(); // Adiciona mascaras pelo atributo [imask-$mascara]


(() => {
	if (!window.hasOwnProperty("subMenuItens")) return;
	const menu = multiLevelMenu(subMenuItens).init();
	window.menu = menu;
	//
})();

GLOBAL.addRequiredLabel(document.querySelector("form"));

window.modalFiltrar = modalFiltrar;
window.myLoader = loader;

// console.log(modalFiltrar)

//adiciona a classe disabled no container, quando o select tem a classe disabled

document.addEventListener("DOMContentLoaded", () =>
	document.body.classList.add("DOMContentLoaded")
);
