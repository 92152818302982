export default function tabs() {
	const attr = "[js-tab]";
	//console.log("rodei o tab");
	const tabs = document.querySelectorAll(`.tabs${attr}`);

	if (!tabs.length) return;

	tabs.forEach((t) => initTab(t));

	function initTab(tab) {
		// console.log(tab)
		const collapses = tab.querySelectorAll(".tab-collapse");

		const tabItens = tab.querySelectorAll(".tabs-wrapper .tab-item");

		tabItens.forEach((t) => {
			t.addEventListener("click", (e) => {
				collapses.forEach((c) => c.classList.remove("active"));
				tabItens.forEach((c) => c.classList.remove("active"));
				t.classList.add("active");
				console.log(e.target);
				const att = e.target.dataset.tab;
				collapses.forEach((c) => {
					const attCollapse = c.getAttribute("data-tab");
					// console.log(attCollapse, att);
					console.log(att, attCollapse);
					if (attCollapse == att) {
						c.classList.add("active");
					}
				});
			});
		});
	}
}
