export default function showToast() {
	const { url } = GLOBAL
	if (url.searchParams.has('mensagem_toastify')) {
		const mensagemToastify = url.searchParams.get('mensagem_toastify')
		if (mensagemToastify.includes('anos não atingido')) {
			Toastify({
				text: mensagemToastify,
				duration: 3000,
				close: true,
				gravity: "top", // `top` or `bottom`
				position: "center",
				offset: {
					x: 0,
					y: '40vh'
				},
				backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)"
			}).showToast();
		} else {
			Toastify({
				text: mensagemToastify,
				duration: 3000,
				position: 'center'
			}).showToast();
		}


		url.searchParams.delete('mensagem_toastify');
		window.history.pushState({}, '', url);
	}
}